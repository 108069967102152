<template>
  <Dialog :name="name">
    <template #title>
      <div>Подтверждение переноса:</div>
    </template>

    <time-left />

    <el-row :class="[{ 'flex-w': isMobile }, 'btn-container']">
      <iq-title :class="{ desktopHeader: !isMobile, mobileHeader: isMobile }">
        Ближайший доступный тайм слот:
      </iq-title>
      <div class="label-date">
        {{ `${dateFormat.date} c ${dateFormat.from} до ${dateFormat.to}` }}
        <span v-if="dateFormat.note" class="label-date__note">
          ({{ dateFormat.note }})
        </span>
        :
      </div>
    </el-row>
    <div class="btn-container">
      <iq-button color="main-dark" size="fullWidth" @onClick="setTimeslotTime">
        <i class="el-icon-check button-icon" />
        Подтвердить перенос
      </iq-button>
      <div style="margin-top: 22px; width: 100%">
        <iq-button color="gray" size="fullWidth" @onClick="closeModal">
          Отказаться от переноса
        </iq-button>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { DATE_FORMAT_RU, GET_TIME } from '@/constants/date'
import { DEFAULT_TIMEZONE, textTimezones } from '@/core'
import { DR_GET_TERMINALS } from '@/store/actions'
import { TIMESLOT_SHIFT_ACCEPT } from '@/constants/dialogs'
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton'
import IqTitle from '@/UI/typography/IqTitle'
import TimeLeft from '@/views/drivers-home/components/TimeLeft'
import moment from '@/core/date'

export default {
  name: 'TimeslotShiftAccept',
  components: {
    TimeLeft,
    Dialog,
    IqButton,
    IqTitle,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: TIMESLOT_SHIFT_ACCEPT,
    }
  },
  computed: {
    ...mapGetters({
      terminalsList: DR_GET_TERMINALS,
    }),
    dateFormat() {
      if (isEmpty(this.params)) {
        return { date: '', from: '', to: '', note: '' }
      }

      let currentTerminal = this.terminalsList.find(
        item => item.id === this.params.unload_id,
      )

      moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
      let dateFromTz = moment(this.params.window_from).format('X')
      let dateToTz = moment(this.params.window_to).format('X')

      moment.tz.setDefault(textTimezones[currentTerminal.timezone_utc_offset])

      return {
        date: moment(dateFromTz, 'X').format(DATE_FORMAT_RU),
        from: moment(dateFromTz, 'X').format(GET_TIME),
        to: moment(dateToTz, 'X').format(GET_TIME),
        note:
          currentTerminal.timezone_utc_offset === DEFAULT_TIMEZONE
            ? ''
            : 'Местное время!',
      }
    },
  },
  methods: {
    setTimeslotTime() {
      this.$emit('acceptTimeslot')
    },
    closeModal() {
      this.$emit('declineTimeslot')
    },
  },
}
</script>

<style lang="sass">
.btn-container
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: column
  margin-bottom: 16px
.label-date
  margin: 20px 0 30px
  font-size: 32px
  font-weight: bold
  &__note
    font-size: 20px
    color: $btn-orange

.timer-slots
  margin: 16px 0
  display: flex
  font-size: 16px !important
  justify-content: space-between
  align-items: center
  > span
    display: block
    color: $btn-orange
    font-weight: bold
    flex-shrink: 0
</style>
